import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "@igloonet-web/shared-ui/themes/igloonet"
import { Link, Slider, ContactFormJobs } from "@igloonet-web/shared-ui"

import {
  JobHeader,
  JobIntroduction,
  JobSection,
  JobWrapper,
  JobMainPhoto,
  Accordion,
} from "../../components"

import ProfileAdam from "../../images/team/profile/adam.jpg"
import ProfileAdamFunny from "../../images/team/profile-funny/adam.jpg"
import ProfilePeta from "../../images/team/profile/petr.jpg"
import ProfileKepi from "../../images/team/profile/kepi.jpg"
import ProfileLukas from "../../images/team/profile/lukas.jpg"
import ProfileMichelin from "../../images/team/profile/michelin.jpg"
import ProfileSobol from "../../images/team/profile/sobol.jpg"

import JobDetailBody from "../../components/jobs/job-detail-body"
import JobDetailContent from "../../components/jobs/job-detail-content"
import { BENEFITS } from "../../model/benefits"
import BenefitPenguin from "../../images/jobs/benefit_penguin.svg"
import PositionStamp from "../../components/position-stamp"

const ObchodnikJuniorDetail: React.FC = () => {
  const personSlider = [
    {
      id: 1,
      imageSrc: ProfileAdam,
      name: "Adam Šilhan",
      position: "Vedoucí obchodu",
    },
    {
      id: 2,
      imageSrc: ProfilePeta,
      name: "Petr Polášek",
      position: "Junior obchodník",
    },
    {
      id: 3,
      imageSrc: ProfileKepi,
      name: "Kepi",
      position: "Vedoucí hostingu & devops",
    },
    {
      id: 4,
      imageSrc: ProfileLukas,
      name: "Lukáš Havlík",
      position: "Vedoucí marketingového týmu",
    },
    {
      id: 5,
      imageSrc: ProfileMichelin,
      name: "Michal Polášek",
      position: "Vedoucí back office",
    },
    {
      id: 6,
      imageSrc: ProfileSobol,
      name: "Jan Nobilis",
      position: "Vedoucí vývoje",
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <JobWrapper>
        <JobHeader />

        <JobDetailBody>
          <PositionStamp />
          <JobDetailContent className="px-2 px-lg-0">
            <JobIntroduction title="Obchodník junior / presales">
              <p>
                <strong>
                  Ahoj, jsme <Link to="/">igloonet</Link>,{" "}
                </strong>
                pod jednou střechou pomáháme zákazníkům jak s&nbsp;vývojem
                e&#8209;shopů, tak s&nbsp;hostingem a&nbsp;on&#8209;line
                propagací.
              </p>
              <p>
                Nejvíc zkušeností máme se středními a&nbsp;většími
                e&#8209;commerce klienty, v&nbsp;poslední době ale čím dál
                častěji pomáháme firmám také s&nbsp;HR marketingem
                a&nbsp;employer brandingem. Z&nbsp;
                <a href="/reference">našich referencí</a> nejlépe poznáš, co
                vlastně umíme.
              </p>
              <p>
                Sídlíme v&nbsp;malebných a&nbsp;klidných brněnských Řečkovicích,
                ty ale můžeš fungovat odkudkoli chceš. Jen úvodní dva měsíce tě
                budeme při zaučování potřebovat fyzicky v kanceláři.
              </p>
              <p>
                Počítáme, že se při shánění zakázek zaměříš kromě jižní Moravy
                i&nbsp;na Prahu, kam se postupně rozrůstáme.
              </p>
            </JobIntroduction>

            <JobSection title="Prodávej služby, kterým budeš věřit">
              <p>
                Nejsme továrna s&nbsp;týmem juniorů, co se na klientech teprve
                učí&nbsp;–&nbsp;naši specialisté mají průměrně 6+&nbsp;let
                zkušeností. Je to potřeba, zpravidla totiž neděláme jen
                jednoduchou „klikací“ práci.
              </p>
              <p>
                Jeden specialista se netopí v desítkách projektů,
                naopak&nbsp;–&nbsp;na jedné zakázce spolupracuje více lidí.
                Nehrozí nám proto tunelové vidění (neaplikujeme stále ten samý
                zajetý přístup) a&nbsp;snažíme se na každý projekt dávat
                naprostý focus.
              </p>
              <p>
                Dlouhodobě se snažíme, abychom měli maximálně jednoho juniora na
                seniora. Díky tomu se u&nbsp;nás lidé rychle rozvíjí. Chceme
                spokojené zaměstnance, a&nbsp;když už někdo odchází, většinou
                jde na manažerskou pozici.
              </p>
              <p>
                Opřeš se tak o&nbsp;silný tým, který nejenže udělá vše pro to,
                aby splnil klientova očekávání, ale má k tomu i&nbsp;dostatečné
                schopnosti a&nbsp;zkušenosti.
              </p>
            </JobSection>

            <JobSection title="S klienty můžeš obchodovat na osobních schůzkách nebo přes videohovory">
              <p>
                Jsme <em>digital first</em>, ale respektujeme, že někteří
                klienti jsou bez osobního kontaktu nesví. Záleží tedy dost
                i&nbsp;na tobě, jak moc chceš prodávat face&#8209;to&#8209;face
                a&nbsp;v&nbsp;jaké míře pro to využiješ technologie 21. století.
              </p>
            </JobSection>

            <JobSection title="Jak poznáš, že jsi náš člověk?">
              <p>
                S&nbsp;aktivním obchodem ještě příliš zkušeností nemáš? Nevadí.
                Spoustu bodů navíc ale získáš, pokud se o&nbsp;digitální svět už
                aktivně zajímáš, něco o&nbsp;marketingu či HR znáš ze školy či
                z&nbsp;praxe, anebo dokonce něco vyvíjíš nebo vedeš vlastní
                e&#8209;shop.
              </p>
              <p>
                Bez dalšího (sebe)vzdělávání to rozhodně nepůjde. Cílem je
                vypracovat se spíš na obchodníka&#8209;konzultanta než čistého
                obchodníka. Je důležité pochopit, co klient potřebuje,
                a&nbsp;nesnažit se jen prodávat, co se zrovna hodí.
              </p>
              <p>
                Pokud už toho máš v obchodu za sebou víc, bude pro tebe
                relevantní spíš inzerát na{" "}
                <a href="/jobs/zkuseny-obchodnik">zkušeného obchodníka</a>.
              </p>
            </JobSection>

            <JobSection title="Jak bude vypadat tvá práce">
              <ul>
                <li>Oslovování potenciálních klientů/partnerů.</li>
                <li>
                  Pomoc s&nbsp;přípravou obchodních materiálů a&nbsp;rešerší
                  trhu.
                </li>
                <li>
                  Zaznamenávání všech svých aktivit do CRM systému (Close).
                </li>
                <li>
                  Aktivní vzdělávání se v tom, co děláme, a&nbsp;poznávání
                  e&#8209;commerce a&nbsp;digitálního světa obecně.
                </li>
              </ul>
              <p>
                Aktivní obchod je někdy tvrdý, proto je potřeba máknout,
                i&nbsp;když se úplně nedaří. Navíc je nutné pořád přemýšlet nad
                tím, co dělat líp, jak upravit předmět e&#8209;mailu, co zlepšit
                na vedení schůzky... Bez pozitivního nastavení a zvídavého
                přístupu to moc nepůjde.
              </p>
            </JobSection>

            <JobSection title="V čem ti pomůžeme">
              <ul>
                <li>
                  Budeš mít průběžně doplňovanou databázi potenciálních klientů
                  a dřívějších kontaktů.
                </li>
                <li>Projdeme s&nbsp;tebou, jak vypadá náš obchodní proces.</li>
                <li>
                  Naučíme tě, jak poznat, jestli je u potenciálního zákazníka
                  fit a&nbsp;dává smysl se bavit dál, nebo se raději zaměřit
                  jinam.
                </li>
                <li>
                  Připravíme hlavní body ke call scriptu, cold e&#8209;mailům
                  i&nbsp;schůzkám a&nbsp;pomůžeme ti vytvořit své vlastní, aby
                  ti šly dobře „přes pusu“.
                </li>
                <li>
                  Je potřeba být nejen profesionál, ale i&nbsp;tak působit.
                  Projdeme proto společně obsahový plán k&nbsp;tvému LinkedInu.
                </li>
              </ul>
            </JobSection>

            <JobSection title="Naše sny">
              <ul>
                <li>Máš bohaté zkušenosti se social sellingem.</li>
                <li>
                  A&nbsp;k&nbsp;tomu ambici nejen prodávat, ale také dle situace
                  na trhu rozšiřovat/zužovat naše služby.{" "}
                </li>
              </ul>
            </JobSection>

            <JobSection title="Co ti můžeme nabídnout?">
              <p>To hlavní by měla být práce, co dává smysl.</p>
              <p>
                Pracujeme v Close, pro obchodníky velmi přívětivém CRM. Budeš
                mít nachystanou a&nbsp;průběžně doplňovanou databázi kontaktů,
                k&nbsp;tomu call a&nbsp;e&#8209;mail scripty.{" "}
              </p>
              <p>
                V rámci rozvoje ti pomůžeme jak po produktové stránce, tak po té
                obchodní, aby se úspěch dostavil co nejdřív.
              </p>
              <p>
                Z&nbsp;hlediska finančního ohodnocení nabízíme základ 30 tisíc
                měsíčně. Když se ti bude dařit domlouvat schůzky, pak 37,5 tisíc
                a&nbsp;k&nbsp;tomu provize z&nbsp;dohodnutých zakázek.
                O&nbsp;konkrétním nastavení modelu hodnocení se s&nbsp;tebou
                rádi pobavíme.
              </p>
            </JobSection>

            <Accordion
              modal
              mainTitle="Co dostaneš za benefity?"
              data={BENEFITS}
              mainImage={BenefitPenguin}
            />

            <JobSection title="Kdo tě povede?">
              <JobMainPhoto src={ProfileAdamFunny} imgTitle="Adam">
                <p className="pt-2">
                  Jsem Adam. V&nbsp;igloonetu mám na starosti získávání
                  zákazníků (obchod i&nbsp;propagaci) a&nbsp;celkový rozvoj
                  našich produktů tak, abychom nezaspali dobu. Nejsem primárně
                  obchodník, ale už jsem do firmy přivedl pár velkých zakázek.
                  Naučím tě všechno, co budu moci. Tam, kde má expertíza končí,
                  začíná externí školení a&nbsp;mentoring. Pokud se budeš
                  snažit, rád ti pomůžu k úspěchu.
                </p>
                <p>
                  Zaměřuji se hlavně na marketingová data a&nbsp;automatizace.
                  S&nbsp;klienty nejraději konzultuji vyhodnocování kampaní
                  s&nbsp;přesahem do práce s&nbsp;customer value modely.
                  Mentoroval a&nbsp;školil jsem například pro Kiwi, Student
                  Agency, T&#8209;Mobile.
                </p>
              </JobMainPhoto>
            </JobSection>
          </JobDetailContent>

          <Slider title={"S kým budeš pracovat?"} data={personSlider} />
          <ContactFormJobs
            heading="Napiš Adamovi a zbytečně to neodkládej"
            modal
            lang="csTykani"
          />
        </JobDetailBody>
      </JobWrapper>
    </ThemeProvider>
  )
}

export default ObchodnikJuniorDetail
